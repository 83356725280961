import React, { useEffect, useRef, useState } from 'react';
import * as Tone from 'tone';
import { Canvas } from "@react-three/fiber";
import {isMobile} from 'react-device-detect';
import { Layout, Card, Col, Row, Button, Drawer, FloatButton, Tag, InputNumber, Modal, Slider, Tooltip } from 'antd';
import {
  PlayCircleFilled,
  CaretRightOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  QuestionCircleOutlined,
  LikeOutlined,
  SoundOutlined
} from '@ant-design/icons';
import Icon from '@ant-design/icons';
import ShaderComponent from './components/ShaderComponent';
import usePageVisibility from './components/PageVisibility';

import RCLogo from './assets/images/logo192.png';
import WhiteNoise from './assets/images/white.jpg';
import BrownNoise from './assets/images/brown.jpg';
import SineWave from './assets/images/sine.jpg';
import Binaural from './assets/images/binaural.jpg';

import { ReactComponent as StopSquareFilled } from './assets/images/StopSquareFilled.svg';
import { ReactComponent as StopCircleFilled } from './assets/images/StopCircleFilled.svg';

import './App.css';

const { Header, Content, Footer } = Layout;
const { Meta } = Card;

const soundOptions = [
  {
    type: "White Noise",
    image: WhiteNoise,
    description: "White Noise contains all audible frequencies with equal intensity, creating a soothing and consistent sound. can help drown out background noise, promoting better concentration and focus. It can also aid in relaxation and sleep by blocking intrusive sounds and creating a calming environment."
  },
  {
    type: "Brown Noise",
    image: BrownNoise,
    description: "Brown noise has a gentle, rumbling quality that can help mask sudden noises and promote relaxation. Many people find brown noise helpful for sleep, as it creates a comforting atmosphere conducive to deep rest. It can also be used for relaxation, meditation, and increasing focus during work or study sessions."
  },
  {
    type: "40Hz Pure Sine",
    image: SineWave,
    description: "Gamma waves (40Hz) are associated with cognitive processes such as memory, attention, and perception. Studies have suggested that exposure to gamma wave stimulation can enhance mental clarity, focus, and information processing."
  },
  {
    type: "40Hz Binaural",
    image: Binaural,
    description: "Listening to binaural beats can help induce different mental states, such as relaxation, focus, or even sleep. When the difference between the frequencies is around 40Hz, it is believed to stimulate the brain's gamma waves, promoting heightened cognition, increased focus, and improved problem-solving abilities."
  },
];

const AudioAnalyser = () => {
  const analyserFFT = useRef(new Tone.Analyser('fft', 1024));
  const analyserWaveform = useRef(new Tone.Analyser('waveform', 4096));
  const canvasRef = useRef(null);
  const animationIdRef = useRef(null); // To store the requestAnimationFrame ID

  Tone.Destination.chain(analyserFFT.current);

  useEffect(() => {
    animationIdRef.current = requestAnimationFrame(updateVisualization);

    return () => {
      cancelAnimationFrame(animationIdRef.current); // Cancel the animation frame
    };
  }, []);

  const updateVisualization = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    ctx.fillStyle = 'rgba(0, 0, 0, 1)';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Draw spectrum
    const dataArrayFFT = analyserFFT.current.getValue();
    const barWidth = (canvas.width / dataArrayFFT.length) * 2.5;

    dataArrayFFT.forEach((item, index) => {
      const y = (item + 140) * 2;
      ctx.fillStyle = 'rgba(0, 200, 0, 1)';
      ctx.fillRect(index * barWidth, canvas.height - y, barWidth, y);
    });

    animationIdRef.current = requestAnimationFrame(updateVisualization);
  };

  return <canvas ref={canvasRef} width={800} height={200} />;
};

const BinauralOptions = ({ centralFrequency, setCentralFrequency }) => {
  const [inputValue, setInputValue] = useState(centralFrequency);

  useEffect(() => {
    setInputValue(centralFrequency);
  }, [centralFrequency]);

  const handleCentralFrequencyChange = (value) => {
    const newInputValue = value;
    setInputValue(newInputValue);

    const newFrequency = Number(newInputValue);
    if (!isNaN(newFrequency) && newFrequency >= 20) {
      setCentralFrequency(newFrequency);
    }
  };

  return (
    <Row justify="space-evenly" align="middle">
      <Col span={24}>
        <Tag color="blue" className="left-freq">{centralFrequency - 20}</Tag>
        <InputNumber
          value={inputValue}
          min={20}
          controls={true}
          style={{
            maxWidth: 100
          }}
          onChange={handleCentralFrequencyChange}
        />
        <Tag color="green" className="right-freq">{centralFrequency + 20}</Tag>
      </Col>
    </Row>
  );
};

function App() {
  const [selectedOption, setSelectedOption] = useState(null);
  const [centralFrequency, setCentralFrequency] = useState(440);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentSynth, setCurrentSynth] = useState(null);
  const volumeControl = React.useRef(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [placement, setPlacement] = useState('bottom');
  const [descriptionVisible, setDescriptionVisible] = useState(false);
  const [feedbackVisible, setFeedbackVisible] = useState(false);
  const [disclaimerVisible, setDisclaimerVisible] = useState(false);
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const isVisible = usePageVisibility();

  useEffect(() => {
    const isAccepted = localStorage.getItem('gammaAccepted');
    if (isAccepted) {
      setDisclaimerVisible(!JSON.parse(isAccepted));
    } else {
      setDisclaimerVisible(true);
    }
  }, [disclaimerAccepted])

  useEffect(() => {
    const isAccepted = localStorage.getItem('gammaAccepted');
    console.log({isAccepted}, isAccepted == false)
    if (isAccepted) {
      setDisclaimerAccepted(JSON.parse(isAccepted));
    } else {
      localStorage.setItem('gammaAccepted', JSON.stringify(false));
    }
  }, [])

  const showDisclaimer = () => {
    setDisclaimerVisible(!disclaimerVisible);
  };

  const handleAcceptDisclaimer = () => {
    setDisclaimerVisible(false);
    setDisclaimerAccepted(true);
    localStorage.setItem('gammaAccepted', JSON.stringify(true));
  };

  const handleRejectDisclaimer = () => {
    setDisclaimerVisible(false);
  };

  const showFeedback = () => {
    setFeedbackVisible(!feedbackVisible);
  };

  const showDescription = () => {
    setDescriptionVisible(!descriptionVisible);
  };

  const showDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
 
  const onClose = () => {
    setDrawerOpen(false);
  };

  useEffect(() => {
    if (isMobile) {
      if (isVisible === true) {
        play(selectedOption);
      } else if (isVisible === false) {
        stop();
      }      
    };
    console.log({isVisible, isMobile})
  }, [isVisible]);

  useEffect(() => {
    Tone.start();
    Tone.Destination.volume.value = -35;

    return () => {
      Tone.Transport.stop();
      Tone.Transport.cancel();
    };
  }, []);

  useEffect(() => {
    if (currentSynth && currentSynth.length) {
      currentSynth[0].frequency.rampTo(centralFrequency - 20, 0.1);
      currentSynth[1].frequency.rampTo(centralFrequency + 20, 0.1);
    }
  }, [centralFrequency]);

  const handleCategoryClick = (option) => {
    if (selectedOption === option) {
      stop();
      setSelectedOption(null);
    } else {
      stop();
      setSelectedOption(option);
      play(option);
    }
  };

  const play = (option) => {
    if (option === 'White Noise') {
      const whiteNoiseSynth = new Tone.Noise('white').toDestination();

      whiteNoiseSynth.fadeIn = 0.5;
      whiteNoiseSynth.fadeOut = 0.5;

      setCurrentSynth(whiteNoiseSynth);
      whiteNoiseSynth.start();
    } else if (option === 'Brown Noise') {
      const brownNoiseSynth = new Tone.Noise('brown').toDestination();

      brownNoiseSynth.fadeIn = 0.5;
      brownNoiseSynth.fadeOut = 0.5;

      setCurrentSynth(brownNoiseSynth);
      brownNoiseSynth.start();
    } else if (option === '40Hz Pure Sine') {
      const volume = new Tone.Volume(-70).toDestination();
      const pureSineSynth = new Tone.Oscillator(40, 'sine').connect(volume).start();
      volume.volume.rampTo(0, 0.5); // fade in

      setCurrentSynth(pureSineSynth);
    } else if (option === '40Hz Binaural') {
      const volume = new Tone.Volume(-70).toDestination();
      const leftEarFrequency = centralFrequency - 20;
      const rightEarFrequency = centralFrequency + 20;

      // create Panner nodes for each Oscillator
      const pannerLeft = new Tone.Panner(-1).toDestination();  // Pan fully left
      const pannerRight = new Tone.Panner(1).toDestination();  // Pan fully right

      const binauralSynthLeft = new Tone.Oscillator(leftEarFrequency, 'sine').connect(pannerLeft).connect(volume).start();
      const binauralSynthRight = new Tone.Oscillator(rightEarFrequency, 'sine').connect(pannerRight).connect(volume).start();
      volume.volume.rampTo(0, 0.5); // fade in

      setCurrentSynth([binauralSynthLeft, binauralSynthRight]);
    }

    setIsPlaying(true);
  };

  const stop = () => {
    if (currentSynth) {
      if (Array.isArray(currentSynth)) { // Handle case for '40Hz Binaural'
        currentSynth.forEach((synth) => {
          const volume = synth.volume;
          volume.rampTo(-70, 0.5); // fade out
          setTimeout(() => {
            synth.stop();
            synth.dispose();
          }, 500); // delay stop and dispose until after fade out
        });
      } else if (currentSynth instanceof Tone.Noise) { // Handle case for 'White Noise' and 'Brown Noise'
        currentSynth.stop("+0.5"); // use built-in fade out
        setTimeout(() => {
          currentSynth.dispose();
        }, 500);
      } else if (currentSynth instanceof Tone.Oscillator) { // Handle case for '40Hz Pure Sine'
        const volume = currentSynth.volume;
        volume.rampTo(-70, 0.5); // fade out
        setTimeout(() => {
          currentSynth.stop();
          currentSynth.dispose();
        }, 500); // delay stop and dispose until after fade out
      }
      setCurrentSynth(null);
    }

    setIsPlaying(false);
  };

  const handleMute = () => {
    Tone.Destination.mute = !isMuted;
    setIsMuted(!isMuted);
    console.log(isMuted)
  };

  const handleVolumeInputChange = (value) => {
    Tone.Destination.volume.rampTo(value, 0.1);
  };

  return (
    <Layout className="app-layout">
      <Header
        style={{
          backgroundColor: 'black'
        }}
      >
        <Row justify="space-evenly" align="middle">
          <Col className="logo-container">
            <a href='https://realitymgmt.com/' target="_blank" rel="noreferrer" alt='Reality Center'>
              <img className="rc-logo" src={RCLogo} alt='reality-center-logo' />
            </a>
          </Col>
        </Row>
      </Header>
      <Content
        style={{
          margin: '24px 16px 0',
          overflowX: 'hidden',
          overflowY: 'auto',
        }}
      >
        <div className="App">
          <Row
            gutter={[{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            },{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }]}
          >
          {soundOptions.map(o => 
            <Col xs={12} sm={12} md={6} lg={6} xl={6} key={o.type}>
              <Card
                hoverable
                style={{
                }}
                onClick={() => handleCategoryClick(o.type)}
                cover={
                  <>
                    <div className='playback-card'>
                    {isPlaying && selectedOption === o.type ? 
                      <Icon 
                        className='stop-card-icon'
                        component={StopCircleFilled} 
                        style={{ fontSize: 84 }} /> : 
                      <PlayCircleFilled
                        className='play-card-icon'
                        style={{ fontSize: 84 }}
                      />
                    }                    
                    </div>
                    <img alt={o.type} src={o.image} />
                  </>
                }
              >
                <Meta title={o.type} />
              </Card>
            </Col>
          )}

          </Row>

          {/* Volume slider */}
          <Row className="vol-slider" justify="center">
            <Col className="icon-wrapper">
              <Slider
                defaultValue={-35}
                min={-70}
                max={0}
                tooltip={{
                  open: false,
                }}
                onChange={handleVolumeInputChange}
                style={{
                  width: 129
                }}
                ref={volumeControl}
              />
              <Tooltip title={isMuted ? "Unmute" : "Mute"}>
                <SoundOutlined
                  className="audio-icon"
                  style={{
                    fontSize: 32,
                    color: isMuted ? 'red' : 'rgba(255, 255, 255, 1)'
                  }}
                  onClick={handleMute}
                />
              </Tooltip>
            </Col>
          </Row>

          {/* Visualizations */}
          {selectedOption === '40Hz Binaural' && (
            <BinauralOptions
              centralFrequency={centralFrequency}
              setCentralFrequency={setCentralFrequency}
            />
          )}
          <Modal
            className='description-modal' 
            title={soundOptions.find(o => o.type === selectedOption)?.type}
            centered
            open={selectedOption !== null && descriptionVisible} 
            onCancel={showDescription}
            mask={false}
            footer={null}
          >
            <p>{soundOptions.find(o => o.type === selectedOption)?.description}</p>
          </Modal>
          <Modal
            className='feedback-modal'
            centered
            open={feedbackVisible} 
            onCancel={showFeedback}
            mask={false}
            footer={null}
            zIndex={9999999}
            bodyStyle={{
              maxHeight: 539
            }}
          >
            <p>
              <iframe
                id="JotFormIFrame-231866048547162"
                title="Gamma Focus Feedback"
                onload="window.parent.scrollTo(0,0)"
                allowtransparency="true"
                allowfullscreen="true"
                allow="geolocation; microphone; camera"
                src="https://form.jotform.com/231866048547162"
                frameborder="0"
                style={{
                  minWidth: '100%',
                  maxWidth: '100%',
                  height: 539,
                  border: 'none'
                }}
                scrolling="no"
              >
              </iframe>
          </p>
          </Modal>
          <Modal
            className='disclaimer-modal' 
            title='GAMMA by Reality Center'
            centered
            open={disclaimerVisible} 
            closable={false}
            maskClosable={false}
            mask={true}
            footer={[
              <Button
                size="small"
                key="link"
                href="https://realitymgmt.com/"
                type="primary"
                danger
              >
                Reject
              </Button>,
              <Button size="small" key="submit" type="primary" onClick={handleAcceptDisclaimer}>
                Accept
              </Button>,
            ]}
          >
            <p>Welcome to our free audio tool, GAMMA.</p>
            <p>Here, you'll find a variety of noises and 40Hz sounds specifically designed to improve focus and cognitive function. The research in this field is rapidly expanding, and we aim to provide a safe platform for you to explore its potential benefits.</p>
            <p>To sample a sound, simply click the play button. For more information, click the question mark located at the bottom right during playback. Wear headphones for the best experience.</p>
            <p><strong>Use caution as this tool is experimental.</strong></p>
            <p>Begin with a low volume setting and ensure that your device's physical mute button/switch is unmuted. Please consider hitting the like button at the bottom left to share your feedback after use.</p>
            <p>Enjoy!</p>
          </Modal>
          {false && selectedOption !== null && <AudioAnalyser />}
          <Drawer
            className='viz-drawer'
            placement={placement}
            width={'100vw'}
            height={'100vh'}
            onClose={onClose}
            open={drawerOpen}
            headerStyle={{
              display: 'none'
            }}
            bodyStyle={{
              padding: 0,
              opacity: 1
            }}
            style={{
              backgroundColor: 'rgba(31, 31, 31, 0.9)'
            }}
          >
            <Canvas>
              <ShaderComponent />
            </Canvas>
            {false && selectedOption !== null && descriptionVisible &&
              <Row className='sound-description'>
                <Col span={24}>
                  {soundOptions.find(o => o.type === selectedOption)?.description}
                </Col>
              </Row>
            }
          </Drawer>
        </div>
      </Content>
      <Footer
        style={{
          textAlign: 'center',
          padding: 0
        }}
      >
        {false &&
        <Button 
          type="primary" 
          shape="circle"
          size="large"
          className='play-stop'
          disabled={selectedOption !== null ? false : true}
          onClick={isPlaying ? stop : () => play(selectedOption)}
          icon={isPlaying ? 
            <Icon 
            className='stop-icon'
              component={StopSquareFilled} 
              style={{ fontSize: 40 }} /> : 
            <CaretRightOutlined
              className='play-icon'
              style={{ fontSize: 32 }}
            />
          }
        />
        }
        {false && <Row
          className='footer-background'
        />}
          <FloatButton
            className='feedback-trigger'
            shape="square"
            type={feedbackVisible ? "default" : "primary"}
            style={{
              left: 14,
              bottom: 24,
              fontSize: 30,
              lineHeight: 0.7,
              position: 'fixed'
            }}
            icon={<LikeOutlined />}
            onClick={showFeedback}
          />
          {drawerOpen === false &&
            <FloatButton
              className='shader-trigger'
              disabled={selectedOption !== null ? false : true}
              shape="square"
              type={descriptionVisible ? "primary" : "default"}
              style={{
                right: 56,
                bottom: 24,
                fontSize: 30,
                lineHeight: 0.7,
                position: 'fixed'
              }}
              icon={<QuestionCircleOutlined />}
              onClick={showDescription}
            />
          }
          <FloatButton
            className='shader-trigger'
            shape="square"
            type="primary"
            style={{
              right: 14,
              bottom: 24,
              fontSize: 30,
              lineHeight: 0.7,
              position: 'fixed'
            }}
            icon={drawerOpen ? <EyeInvisibleOutlined /> : <EyeOutlined />}
            onClick={showDrawer}
          />
      </Footer>
    </Layout>
  );
}

export default App;
