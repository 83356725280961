import React, { useMemo, useRef } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three';
import { Plane } from '@react-three/drei';

const vertexShader = `
  varying vec2 vUv;
  void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`;

const fragmentShader = `
  uniform float u_time;
  varying vec2 vUv;

  float mandelbrot(vec2 z) {
    vec2 c = z;
    float maxIter = 16.0;
    float i;
    for(i=0.0; i<maxIter; i++) {
      float x = (z.x*z.x - z.y*z.y) + c.x;
      float y = (2.0*z.x*z.y) + c.y;
      if((x*x + y*y) > 4.0) break;
      z = vec2(x,y);
    }
    return i/maxIter;
  }

  vec2 rotate(vec2 v, float a) {
    float s = sin(a);
    float c = cos(a);
    mat2 m = mat2(c, -s, s, c);
    return m * v;
  }

  void main() {
    vec2 uv = (vUv * 2.0 - 1.0) * 2.0;
    uv = rotate(uv, 3.14 / -2.0);  // rotate by 90 degrees
    float dist = length(uv);
    vec2 dir = normalize(uv);
    uv += dir * sin(u_time * 1.0) * 0.5;
    float color = pow(0.1 / abs(sin(8.0 * length(uv) + u_time * 2.0)), 1.2);
    float colorR = 0.0; // Removed red color
    float colorG = color * (0.5 + 0.5*sin(6.0 * (dist + u_time * 2.0 * 0.4))); // Half-wave rectified sin for green color
    float colorB = color * (0.5 + 0.5*cos(6.0 * (dist + u_time * 2.0 * 0.4))); // Half-wave rectified cos for blue color
    float mandel = mandelbrot(uv);
    vec3 colorRGB = vec3(colorR * mandel, colorG * mandel, colorB * mandel);
    gl_FragColor = vec4(colorRGB, 1.0);
  }
`;

export const ShaderComponent = () => {
  const uniforms = useRef({
    u_time: { value: 0 }
  });

  useFrame(({ clock }) => {
    uniforms.current.u_time.value = clock.getElapsedTime();
  });

  const material = useMemo(() => new THREE.ShaderMaterial({
    uniforms: uniforms.current,
    vertexShader,
    fragmentShader,
    side: THREE.DoubleSide,
  }), []);

  const { viewport } = useThree();

  return (
    <Plane args={[viewport.width, viewport.height]} material={material} />
  );
};

export default ShaderComponent;
